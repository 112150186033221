import { createWebHistory, createRouter } from 'vue-router'

// Routes
import modulesRoutes from '@/modules/router/index.js'

const router = new createRouter({
    scrollBehavior() { return { x: 0, y: 0 } },
    history: createWebHistory(),
    routes: [
        ...modulesRoutes,
    ],
})

router.beforeEach((to, _, next) => {
    // if (to.meta && !to.meta.notauthentication) {
    //     if (!localStorage.getItem("userData")) {
    //         return next({ name: "auth-login" });
    //     }
    // }
    return next();
})

export default router;