<template>
  <header>
    <div class="logo">
      <img src="@/assets/images/logo/logo.png" />
    </div>
    <div
      class="menu-btn"
      @click="toggleMenu"
    >
      <hr class="burger" />
      <hr class="burger" />
      <hr class="burger" />
    </div>
    <nav
      class="menu"
      :class="{ show: isMenuVisible }"
    >
      <ul class="flex items-center">
        <li>
          <c-button
            variant="outlined"
            text="Consultar Fipe (placa)"
            size="small"
            @click="$router.push({ name: 'shopping-consulting-fipe-plate' })"
          />
        </li>
        <li>
          <a href="/ofertas?repass=true">Repasse</a>
        </li>
        <li>
          <a href="/ofertas?auction=true">Leilão</a>
        </li>
        <li>
          <a href="/ofertas">Estoque</a>
        </li>
        <li v-if="hasLogged && showOffers">
          <router-link :to="getMyOffers">
            Meus Anúncios
          </router-link>
        </li>
        <li v-if="hasLogged && showAccount">
          <router-link :to="{ name: 'account-seller' }">
            Minha conta
          </router-link>
        </li>
        <li>
          <div v-if="hasLogged">
            <div class="avatar">
              <p>{{ nameUserLogged }}</p>
            </div>
          </div>
          <router-link
            v-else
            class="btn-login"
            :to="{ name: 'login' }"
          >
            ENTRAR
          </router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { $loggedUser } from '@/services/userLogged.js'
export default {
  name: 'HeaderDefault',
  data() {
    return {
      isMenuVisible: false
    }
  },
  computed: {
    showOffers() {
      const groups = $loggedUser.getGroups();
      return (groups.includes('lojista') || groups.includes('vendedor'))
    },
    showAccount() {
      const groups = $loggedUser.getGroups();
      return (groups.includes('vendedor'))
    },
    hasLogged() {
      return $loggedUser?.getUser()?.name.length > 0
    },
    nameUserLogged() {
      return $loggedUser?.getUser()?.ab
    },
    getMyOffers() {
      const groups = $loggedUser.getGroups();
      if (groups.includes('lojista')) {
        return { name: 'company-stock-index' }
      }
      if (groups.includes('vendedor')) {
        return { name: 'company-stock-index' }
      }
      return { name: 'seller-stock-index' }
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible
    }
  }
}
</script>

<style scoped>
.avatar {
  background: var(--color-primary);
  color: var(--color-white);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  color: var(--color-black);
}

header {
  padding: 0 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5em;
}

.logo img {
  width: 200px;
}

.menu-btn {
  cursor: pointer;
  display: none;
}

.burger {
  width: 30px;
  height: 3px;
  margin: 6px 0;
  background: var(--color-primary);
}

.menu {
  display: flex;
}

.menu ul {
  list-style: none;
  display: flex;
}

.menu ul li {
  margin-right: 20px;
}

.menu ul li a {
  text-decoration: none;
  font-weight: 500;
}

.menu ul li a:hover {
  color: var(--color-primary);
  transition: all 0.3s linear;
}

.btn-login {
  background: var(--color-primary);
  color: var(--color-white);
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 30px;
}

.btn-login:hover {
  background: transparent !important;
  color: var(--color-primary) !important;
  border-radius: 30px;
  border: 1px solid var(--color-primary);
}

@media screen and (max-width: 768px) {
  .logo img {
    width: 120px;
  }

  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 56px;
    left: 0;
    background-color: var(--color-gray);
    color: var(--color-primary);
    width: 100%;
  }

  .menu.show {
    display: flex;
  }

  .menu ul {
    flex-direction: column;
    line-height: 2rem;
    text-align: center;
  }

  .menu li {
    margin-right: 0;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }

  .menu-btn {
    display: none;
  }
}
</style>
