if(window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations()
  .then(function(registrations) {
    for(let registration of registrations) {
      registration.unregister();
    }
  });
}

import '@/assets/main.css'
import '@/assets/css/grid.css'
import '@/assets/css/spacing.css'

import { createApp } from 'vue'
import App from './App.vue'

// PROPRIOS
import CIcon from '@/core/components/CIcon/Index.vue'

//carrousel
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

// toasts
import 'vue3-toastify/dist/index.css'
import { toast } from 'vue3-toastify'

// icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, far, fab)

import router from './router'
// import store from './store'
// import i18n from '@/libs/i18n'

//VUETIFY
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import {
  VTextField,
  VBtn,
  VAutocomplete,
  VRadioGroup,
  VRadio,
  VSwitch,
  VChip,
  VProgressCircular
} from 'vuetify/components'

import { cTypes } from '@/core/utils/constants'

import { createVuetify } from 'vuetify'
const vuetify = createVuetify({
  theme: {
    colors: {
      primary: '#049b54'
    }
  },
  defaults: {
    VTextField: { color: '#049b54', variant: cTypes.outlined },
    VBtn: { color: '#049b54', variant: cTypes.flat },
    VAutocomplete: { color: '#049b54', variant: cTypes.outlined },
    VRadioGroup: { color: '#049b54' },
    VSwitch: { color: '#049b54' },
    VChip: { color: '#049b54' },
    VProgressCircular: { color: '#049b54' }
  }
})

const app = createApp(App)
  .component('c-loading', VProgressCircular)
  .component('c-radio-group', VRadioGroup)
  .component('c-select', VAutocomplete)
  .component('c-input', VTextField)
  .component('c-radio', VRadio)
  .component('c-switch', VSwitch)
  .component('c-chip', VChip)
  .component('c-button', VBtn)
  .component('c-icon', CIcon)
  .use(router)
  .use(vuetify)

// Mask inputs
import VueMask from '@devindex/vue-mask'
app.use(VueMask)

app.config.globalProperties.$toast = toast
app.mount('#app')
