<template>
  <c-header />

  <div class="flex">
    <c-navigation />

    <!-- conteudo -->
    <router-view v-slot="{ Component }">
      <Transition
        :name="routerTransition"
        mode="out-in"
      >
        <component :is="Component" />
      </Transition>
    </router-view>
  </div>
</template>
<script>
import CNavigation from '@/core/layouts/components/NavigationDefault.vue'
import CHeader from '@/core/layouts/components/HeaderDefault.vue'
export default {
  name: 'LayoutDefault',
  components: {
    CNavigation,
    CHeader
  },
  data() {
    return {
      routerTransition: 'zoom-fade' // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    }
  }
}
</script>

<style scoped>
@import url('@/assets/css/transitions.css');

@media screen and (max-width: 770px) {
  .flex {
    display: grid;
  }
}</style>