import homeRouters from '../home/router/index.js'
import adminRouters from '../admin/router/index.js'
import companyhRouters from '../company/router/index.js'
import sellerRouters from '../seller/router/index.js'
import shoppingRouters from '../shopping/routers/index.js'

export default [
  ...homeRouters,
  ...adminRouters,
  ...companyhRouters,
  ...sellerRouters,
  ...shoppingRouters
]
