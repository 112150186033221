export default [{
  path: '/ofertas',
  name: 'shopping-offers-list',
  meta: { layout: 'shopping' },
  component: () => import('../pages/offers/Index.vue'),
},
{
  path: '/oferta/:id',
  name: 'shopping-offers-details',
  meta: { layout: 'default' },
  component: () => import('../pages/offers/details.vue'),
},
{
  path: '/consultar-fipe-placa',
  name: 'shopping-consulting-fipe-plate',
  meta: { layout: 'default' },
  component: () => import('../pages/fipe/consultar-placa.vue'),
}]
