export default [
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    meta: { layout: 'default' },
    component: () => import('../pages/dashboard/index.vue')
  },
  {
    path: '/admin/users',
    name: 'admin-users-list',
    meta: { layout: 'default' },
    component: () => import('../pages/users/list.vue')
  },
  {
    path: '/admin/user/:id',
    name: 'admin-user-view',
    meta: { layout: 'default' },
    component: () => import('../pages/users/details.vue')
  },
  {
    path: '/admin/companies',
    name: 'admin-companies-list',
    meta: { layout: 'default' },
    component: () => import('../pages/companies/list.vue')
  },
  {
    path: '/admin/company/:id',
    name: 'admin-company-details',
    meta: { layout: 'default' },
    component: () => import('../pages/companies/details.vue')
  },
  {
    path: '/admin/plans',
    name: 'admin-plans-list',
    meta: { layout: 'default' },
    component: () => import('../pages/plans/list.vue')
  },
  {
    path: '/admin/plans/:id',
    name: 'admin-edit-plans',
    meta: { layout: 'default' },
    component: () => import('../pages/plans/edit.vue')
  },
  {
    path: '/admin/payments',
    name: 'admin-payment-list',
    meta: { layout: 'default' },
    component: () => import('../pages/payment/list.vue')
  },

  {
    path: '/admin/company/:id',
    name: 'admin-company-details',
    meta: { layout: 'default' },
    component: () => import('../pages/companies/details.vue')
  },
  {
    path: '/admin/brands',
    name: 'admin-brands-list',
    meta: { layout: 'default' },
    component: () => import('../pages/brands/list.vue')
  },
  {
    path: '/admin/vehicles',
    name: 'admin-vehicles-list',
    meta: { layout: 'default' },
    component: () => import('../pages/vehicles/list.vue')
  },
  {
    path: '/admin/vehicle/:id/versions',
    name: 'admin-vehicles-versions-list',
    meta: { layout: 'default' },
    component: () => import('../pages/vehicles/versions.vue')
  },
  {
    path: '/admin/offers',
    name: 'admin-offers-list',
    meta: { layout: 'default' },
    component: () => import('../pages/offers/list.vue')
  }
]
