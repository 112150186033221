<template>
  <router-link
    v-if="item.route"
    :to="{ name: item.route }"
    class="px-4 flex items-center menu"
  >
    <c-icon
      v-if="item.icon"
      :name="item.icon"
      class="pr-3"
      style="font-size: 1rem"
    />
    <p>{{ item.title }}</p>
  </router-link>
  <a
    v-else-if="item.href"
    class="px-4 flex items-center menu"
    :href="item.href"
  >
    <c-icon
      v-if="item.icon"
      :name="item.icon"
      class="pr-3"
      style="font-size: 1rem"
    />
    <p>{{ item.title }}</p>
  </a>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    item: { type: Object, required: true }
  },
  computed: {
    hasPemission() {
      return true
    }
  }
}
</script>
<style scoped>
.menu {
  color: var(--color-text-light);
}
</style>
