export default [
  {
    path: '/lojista/estoque',
    name: 'company-stock-index',
    meta: { layout: 'default' },
    component: () => import('../pages/stock/Index.vue')
  },
  {
    path: '/lojista/estoque/oferta/:id',
    name: 'company-offer-create',
    meta: { layout: 'default' },
    component: () => import('../pages/offers/Create.vue')
  },
  {
    path: '/lojista/minha-conta',
    name: 'account-store',
    meta: { layout: 'default' },
    component: () => import('../pages/account/index.vue')
  },
  {
    path: '/lojista/pagamento',
    name: 'payment-index',
    meta: { layout: 'default' },
    component: () => import('../pages/payment/index.vue')
  },
  {
    path: '/lojista/assinaturas',
    name: 'subscriptions-index',
    meta: { layout: 'default' },
    component: () => import('../pages/subscriptions/index.vue')
  },
  {
    path: '/lojista/leilao',
    name: 'auction-index',
    meta: { layout: 'default' },
    component: () => import('../pages/auction/index.vue')
  },
  {
    path: '/leilao/:id/:confirm?',
    name: 'auction-details',
    meta: { layout: 'default' },
    component: () => import('../pages/auction/details.vue')
  },
  {
    path: '/leilao/checkout/:id?',
    name: 'auction-checkout',
    meta: { layout: 'default' },
    component: () => import('../pages/auction/checkout.vue')
  },
  {
    path: '/leilao/ticket',
    name: 'auction-ticket',
    meta: { layout: 'default' },
    component: () => import('../pages/auction/ticket.vue')
  }
]
