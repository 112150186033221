<template>
  <router-view v-slot="{ Component }">
    <Transition :name="routerTransition" mode="out-in">
      <component :is="Component" />
    </Transition>
  </router-view>
</template>
<script>
export default {
  name: 'LayoutEmpty',
  data() {
    return {
      routerTransition: 'zoom-fade'
    }
  }
}
</script>

<style scoped>
@import url('@/assets/css/transitions.css');
</style>