const LOGGED_USER = 'app-user-logged'
const LOGGED_USER_GROUPS = 'app-token-group'

class LoggedUser {
  getUser() {
    const userAsString = localStorage.getItem(LOGGED_USER)
    return JSON.parse(userAsString)
  }

  getGroups() {
    const userAsString = localStorage.getItem(LOGGED_USER_GROUPS)
    return JSON.parse(userAsString)
  }
}
export const $loggedUser = new LoggedUser()
