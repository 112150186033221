export default [
  {
    title: 'Dashboard',
    icon: 'chart-line',
    route: 'admin-dashboard',
    rule: ['p.admin.dashboard.view']
  },
  {
    title: 'Clientes',
    icon: 'users',
    route: 'admin-companies-list',
    rule: ['p.admin.company.view']
  },
  {
    title: 'Pagamentos',
    icon: 'hand-holding-dollar',
    route: 'admin-payment-list',
    rule: ['p.admin.payment.view']
  },
  {
    title: 'Planos',
    icon: 'sack-dollar',
    route: 'admin-plans-list',
    rule: ['p.admin.plans.view']
  },
  {
    title: 'Consuta Placa',
    icon: 'sack-dollar',
    route: 'admin-search-plates',
    rule: ['p.admin.searchPlates.view']
  },
  {
    title: 'Sair',
    icon: 'power-off',
    route: 'login',
    rule: []
  }
]
