export default [
  {
    path: '/',
    name: 'home',
    public: true,
    component: () => import('../pages/Index.vue')
  },
  {
    path: '/login',
    name: 'login',
    public: true,
    component: () => import('../pages/Login/Index.vue')
  },
  {
    path: '/esqueci-minha-senha',
    name: 'recover-password',
    public: true,
    component: () => import('../pages/Login/recover-password.vue')
  },
  {
    path: '/me-cadastrar',
    name: 'register',
    public: true,
    component: () => import('../pages/Register/Index.vue')
  },
  {
    path: '/politica-privacidade',
    name: 'privacy-policy',
    public: true,
    component: () => import('../pages/PrivacyPolicy/Index.vue')
  },
  {
    path: '/termo-condicoes',
    name: 'term-conditions',
    public: true,
    component: () => import('../pages/TermConditions/Index.vue')
  },
  {
    path: '/quem-somos',
    name: 'quem-somos',
    public: true,
    component: () => import('../pages/WhoWeAre/Index.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    public: true,
    component: () => import('../pages/Contact/Index.vue')
  }
]
