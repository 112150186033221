<template>
  <ul
    v-if="isOpen"
    class="pt-4 d-block d-md-none"
    style="background: var(--color-gray-500)"
  >
    <li
      v-for="(item, i) in showNavigation"
      :key="i"
      class="mb-4"
    >
      <menu-item
        :item="item"
        @click="isOpen = !isOpen"
      />
    </li>
  </ul>

  <input
    id="check"
    type="checkbox"
  />
  <label
    id="icone"
    for="check"
    class="cursor-pointer"
    @click="isOpen = !isOpen"
  >
    <div
      id="container-hamburguer"
      class="menu"
    >
      <span
        id="hamburguer"
        class="hamburguer"
      ></span>
    </div>
  </label>
</template>

<script>
import navigationAdmin from '@/router/navigation-admin.js'
import navigationStore from '@/router/navigation-store.js'
import MenuItem from '@/core/layouts/components/MenuItem.vue'
export default {
  name: 'NavigationDefault',
  components: {
    MenuItem
  },
  setup() {
    return {
      navigationAdmin,
      navigationStore
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    showNavigation() {
      if (localStorage.getItem('app-token-group')) {
        const group = JSON.parse(localStorage.getItem('app-token-group'))
        if (group.includes('admin')) {
          return this.navigationAdmin
        }

        return this.navigationStore
      }
      return []
    }
  },
  methods: {
    selectedMenu(item) {
      return item.route === this.$route.name
    }
  }
}
</script>

<style scoped>
nav {
  background: var(--color-gray-500);
  width: 250px;
  min-height: 100vh;

  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.item-selected {
  background: var(--color-primary);
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

.item-selected a {
  color: #fff;
}

input {
  display: none;
}

.itemBar {
  display: none;
}

@media screen and (max-width: 770px) {
  .menu {
    border-radius: 50%;
    width: initial;
    height: initial;
    position: unset;
    top: 0px;
    right: 5px;
    z-index: 99;
  }

  .hamburguer {
    position: absolute;
    display: block;
    background: var(--color-primary);
    width: 30px;
    height: 2px;
    top: 28px;
    right: 15px;
    transition: 0.5s ease-in-out;
  }

  .hamburguer:before,
  .hamburguer:after {
    background: var(--color-primary);
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 0.5s ease-in-out;
  }

  .hamburguer:before {
    top: -10px;
  }

  .hamburguer:after {
    bottom: -10px;
  }
}
</style>
