export default [
  {
    title: 'Minha Conta',
    icon: 'address-card',
    route: 'account-store',
    rule: []
  },
  {
    title: 'Meu estoque',
    icon: 'list',
    route: 'company-stock-index',
    rule: []
  },
  {
    title: 'Consultar Leilão',
    icon: 'fa-car-burst',
    route: 'auction-index',
    rule: []
  },
  {
    title: 'Ofertas',
    icon: 'fa-car',
    route: 'shopping-offers-list',
    rule: []
  },
  {
    title: 'Consultar Fipe',
    icon: 'money-bill-trend-up',
    route: 'shopping-consulting-fipe-plate',
    rule: ['p.admin.company.view']
  },
  {
    title: 'Sair',
    icon: 'power-off',
    route: 'login',
    rule: []
  }
]
