<template>
  <div :style="`font-size: ${size}rem;`">
    <font-awesome-icon :icon="[typeIcon, name]" />
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const TypeIcon = {
  solid: 'solid',
  regular: 'regular',
  brand: 'brand'
}

export default {
  name: 'CIcon',
  components: {
    FontAwesomeIcon
  },
  props: {
    name: { type: String, required: true },
    size: { type: String, default: '1' },
    type: { type: String, default: 'solid', validator: (value) => !!TypeIcon[value] }
  },
  computed: {
    typeIcon() {
      switch (this.type) {
        case 'regular':
          return 'far'
        case 'brand':
          return 'fab'
        default:
          return 'fas'
      }
    }
  }
}
</script>