<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import LayoutEmpty from '@/core/layouts/LayoutEmpty.vue'
import LayoutDefault from '@/core/layouts/LayoutDefault.vue'
import LayoutShopping from '@/core/layouts/LayoutShopping.vue'

export default {
  name: 'APP',
  components: {
    empty: LayoutEmpty,
    default: LayoutDefault,
    shopping: LayoutShopping,
  },
  computed: {
    layout() {
      return this.$route?.meta?.layout ?? 'empty'
    }
  }
}
</script>
